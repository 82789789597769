import React from 'react'
import Error from 'next/error'
import { getDisplayName } from 'common'
import moment from 'moment'
import { LocaleProvider } from '../contexts/LocaleContext'

const Hoc = (WrappedPage) => {
  const WithLocale = (props) => {
    const { locale } = props
    moment.locale(locale)
    if (!locale) {
      return <Error statusCode={404} />
    }
    return (
      <LocaleProvider lang={locale}>
        <WrappedPage {...props} />
      </LocaleProvider>
    )
  }
  WithLocale.displayName = `withLang(${getDisplayName(WrappedPage)})`

  return WithLocale
}

export default Hoc
