import React from 'react'

import Link from 'next/link'
import { useRouter } from 'next/router'

import { Container, Row, Col, Button, Card, CardBody } from 'reactstrap'

import ReactHtmlParser from 'react-html-parser'
import { isMobile } from 'react-device-detect'
import SwiperCore, { Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { UsersApi } from 'typescript-axios'
import Discover from '../components/Discover'

import data from '../data/index.json'
import useTranslation from '../hooks/UseTranslation'
import WithLocale from '../hocs/WithLocale'
import Fab from '../components/Fab'
import { HeaderCollapsedContext } from '../contexts/HeaderCollapsedContext'
import LastMinute from '../components/LastMinute'
import { getAxiosParams } from '../utils/client'
import { getGurusForInsta } from '../data/gurus-insta'

// FIXME: remove this SH1T react id swiper package
SwiperCore.use([Pagination, Autoplay])

const USER_SERVICE = new UsersApi(getAxiosParams('https://api.gurubay.co'))

export async function getStaticProps({ locale, defaultLocale }) {
  const gurus = await getGurusForInsta()
  return {
    revalidate: 60,
    props: {
      nav: {
        dark: true,
        classes: 'shadow',
        color: 'transparent'
      },
      footerProps: {
        className: 'bg-white'
      },
      noPaddingTop: true,
      title: null,
      gurus,
      locale,
      defaultLocale
    }
  }
}

const FrontPage = (props) => {
  const { t } = useTranslation()
  const [selection, setSelection] = React.useState(null)
  const { setNavBarColor, setNavBarLight, setNavBarDark } = React.useContext(
    HeaderCollapsedContext
  )

  const bgRef = React.useRef()

  React.useEffect(() => {
    setNavBarDark(true)
    setNavBarLight(false)
    if (!isMobile) setNavBarColor('transparent')
  }, [])

  React.useEffect(() => {
    const handleScroll = () => {
      const th = isMobile ? 0 : bgRef.current?.offsetHeight * 0.6
      const show = window.scrollY > th
      if (show) {
        setNavBarColor('white')
        setNavBarLight(true)
        setNavBarDark(false)
      } else {
        setNavBarColor('transparent')
        setNavBarLight(false)
        setNavBarDark(true)
      }
    }

    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const router = useRouter()
  if (typeof window !== 'undefined' && selection) {
    // useRouter().push({ pathname: '/classes', query: selection })
    router.push({
      pathname: '/classes'
      // query: selection,
    }) // fixme
  }

  return (
    <>
      <Fab onClick={() => router.push('/get-started')}>
        {t('index_cta_guru')}
      </Fab>
      <div className='position-relative'>
        <section ref={bgRef} className='position-relative overlay'>
          <img
            src='https://gurubay-front-res.s3.fr-par.scw.cloud/index.png'
            className='bg-image'
          />
          <Container
            className='text-white overlay-content py-6 d-flex flex-column justify-content-center'
            style={{ height: '66vh' }}
          >
            <Row className='mb-4'>
              <Col xl='10' lg='12'>
                <div className='p-4 p-md-0'>
                  <h2 className='mb-0 font-weight-bold text-shadow'>
                    {ReactHtmlParser(t('index_hero_title'))}
                  </h2>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Container
          className='z-index-20 position-relative'
          style={{ transform: 'translateY(-50%)' }}
        >
          {/* above class necessary to appear on top... */}
          <div className='d-none d-sm-flex justify-content-center'>
            <Link href='/classes' as='/classes'>
              <Button size='lg' className='mr-1 shadow' color='primary'>
                {t('index_cta')}
              </Button>
            </Link>
            <Link href='/get-started' as='/get-started'>
              <Button
                size='lg'
                className='ml-1 shadow'
                color='negative-primary'
              >
                {t('index_cta_guru')}
              </Button>
            </Link>
          </div>
          <div className='d-sm-none'>
            <Link href='/classes' as='/classes'>
              <Button size='lg' block className='shadow' color='primary'>
                {t('index_cta')}
              </Button>
            </Link>
            <Link href='/get-started' as='/get-started'>
              <Button
                size='lg'
                block
                className='shadow'
                color='negative-primary'
              >
                {t('index_cta_guru')}
              </Button>
            </Link>
          </div>
        </Container>
      </div>
      <LastMinute gurus={props.gurus} />
      <section className='py-6 bg-gray-100'>
        <Container>
          <Row>
            {data.topBlocks.blocks.map((block) => (
              <Col
                key={t(block.title)}
                lg='4'
                className='mb-3 mb-lg-0 text-center'
              >
                <div className='px-0 px-lg-3'>
                  <div className='icon-rounded bg-primary-light mb-3'>
                    <svg className='svg-icon text-primary w-2rem h-2rem'>
                      <use
                        xlinkHref={`content/svg/orion-svg-sprite.svg${block.icon}`}
                      >
                        {' '}
                      </use>
                    </svg>
                  </div>
                  <h3 className='h5'>{t(block.title)}</h3>
                  <div
                    className='text-muted'
                    dangerouslySetInnerHTML={{ __html: t(block.content) }}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <section className='py-6 position-relative'>
        <img
          src='https://gurubay-front-res.s3.fr-par.scw.cloud/empty-bg.png'
          alt=''
          className='bg-image'
        />
        <Container>
          <Row>
            <Col lg='2' />
            <Col lg='8'>
              <div className='bg-white rounded-lg shadow p-5'>
                <h5 className='subtitle text-secondary'>
                  {t('index_quick_desc_title')}
                </h5>
                {/* <h2 className="mb-5">{t("index_quick_desc_title")}</h2> */}
                <ul className='fa-ul my-4'>
                  <li className='mb-3 lead'>
                    <span className='fa-li text-primary'>
                      <strong>1</strong>
                    </span>
                    {t('index_quick_desc_content0')}
                  </li>
                  <li className='mb-3 lead'>
                    <span className='fa-li text-primary'>
                      <strong>2</strong>
                    </span>
                    {t('index_quick_desc_content1')}
                  </li>
                  <li className='lead'>
                    <span className='fa-li text-primary'>
                      <strong>3</strong>
                    </span>
                    {t('index_quick_desc_content2')}
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg='2' />
          </Row>
        </Container>
      </section>
      <Discover
        className='py-5'
        title={data.hesitation.title}
        subtitle={data.hesitation.subtitle}
        blocks={data.hesitation.blocks}
      />
      <section className='py-6 position-relative overlay'>
        <img
          src='https://gurubay-front-res.s3.fr-par.scw.cloud/gradient.jpg'
          alt=''
          className='bg-image'
        />
        <div className='overlay-content text-white py-lg-4'>
          <Container>
            <Row>
              <Col lg='6' xs='12' className='mb-5 mb-lg-0 h-100'>
                <h3 className='display-4 font-weight-bold text-shadow mb-4'>
                  {ReactHtmlParser(t('index_jumbotron_title'))}
                </h3>
                <Link href={data.jumbotron.link}>
                  <Button color='primary' className='btn-lg shadow'>
                    {t('index_jumbotron_button')}
                  </Button>
                </Link>
              </Col>
              <Col lg='6' xs='12' className='d-flex align-items-center h-100'>
                <Swiper
                  slidesPerView={1}
                  spaceBetween={50}
                  loop
                  autoplay={{ delay: 4000 }}
                >
                  {[
                    'https://gurubay-front-res.s3.fr-par.scw.cloud/get-started-manage_2.jpg',
                    'https://gurubay-front-res.s3.fr-par.scw.cloud/get-started-dashboard_2.jpg',
                    'https://gurubay-front-res.s3.fr-par.scw.cloud/get-started-profile_2.jpg'
                  ].map((url) => (
                    <SwiperSlide key={url} style={{ height: 'auto' }}>
                      <Card className='border-0 shadow h-100'>
                        <CardBody className='p-4'>
                          <img className='img-fluid' src={url} />
                        </CardBody>
                      </Card>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      {/* <Instagram /> */}
    </>
  )
}

const Page = (props) => <FrontPage {...props} />

export default WithLocale(Page)
