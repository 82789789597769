import React from 'react'

import { Container, Fade } from 'reactstrap'

import SwiperCore, { Autoplay } from 'swiper'
import { isBrowser } from 'common'
import { Swiper, SwiperSlide } from 'swiper/react'
// FIXME: remove this SH1T react id swiper package
SwiperCore.use([Autoplay])

const Instagram = ({ data }) => {
  const params = {
    slidesPerView: 3,
    loop: true,
    autoplay: {
      delay: 4000
    },
    breakpoints: {
      1900: {
        slidesPerView: 12
      },
      1500: {
        slidesPerView: 10
      },
      1200: {
        slidesPerView: 8
      },
      991: {
        slidesPerView: 4
      },
      768: {
        slidesPerView: 4
      }
    }
  }
  return (
    <section>
      <Container fluid className='px-0'>
        <Swiper {...params}>
          {data &&
            data.map(({ img, link }, index) => (
              <SwiperSlide
                key={index}
                style={{ maxHeight: 'calc(min(30vw, 150px))', height: 'auto' }}
              >
                <Fade in={isBrowser()} className='h-100'>
                  <div className='h-100'>
                    <a href={link}>
                      <img
                        src={img}
                        alt=''
                        className='h-100 hover-scale'
                        style={{
                          objectFit: 'cover',
                          width: '100%',
                          maxWidth: '100%'
                        }}
                      />
                    </a>
                  </div>
                </Fade>
              </SwiperSlide>
            ))}
        </Swiper>
      </Container>
    </section>
  )
}

export default Instagram
