import { useContext } from 'react'
import { LocaleContext } from '../contexts/LocaleContext'
import { translate } from '../translations/utils'

export default function useTranslation() {
  const { locale, defaultLocale } = useContext(LocaleContext)
  const t = (key, ...args) => translate(key, locale, defaultLocale, ...args)

  return {
    t,
    locale
  }
}
