import React from 'react'
import Link from 'next/link'
import { Container, Row, Col } from 'reactstrap'

import useTranslation from '../hooks/UseTranslation'
import Instagram from './Instagram'

const LastMinute = (props) => {
  const { gurus, isOnline } = props
  const { t } = useTranslation()

  return (
    <section className={`pt-5 ${props.greyBackground ? 'bg-gray-100' : ''}`}>
      <Container>
        <Col>
          <Row className='mb-0'>
            <Col md='12' className='text-center'>
              <p className='subtitle text-secondary'>
                {t('last_minute_subtitle')}
              </p>
              <h2>{t('last_minute_title')}</h2>
            </Col>
          </Row>
          <Row className='mb-4'>
            <Col md='12' className='text-right'>
              <Link href='/profs'>
                <a className='text-muted text-sm'>
                  {t('last_minute_button')}
                  <i className='fas fa-angle-double-right ml-2' />
                </a>
              </Link>
            </Col>
          </Row>
        </Col>
      </Container>
      <Instagram data={gurus} />
    </section>
  )
}

export default LastMinute
