import React from 'react'

import { Container, Row, Col } from 'reactstrap'
import useTranslation from '../hooks/UseTranslation'
import Icon from './Icon'

const Discover = ({ title, subtitle, ...props }) => {
  const { t } = useTranslation()
  return (
    <section className={props.className}>
      <Container>
        {(title || subtitle) && (
          <div className='text-center pb-lg-4'>
            <p className='subtitle text-secondary'>{t(subtitle)}</p>
            <h2 className='mb-5'>{t(title)}</h2>
          </div>
        )}
        <Row>
          {props.blocks.map((block) => (
            <Col
              key={block.title}
              sm='6'
              lg={12 / props.blocks.length}
              className='mb-3 mb-lg-0'
            >
              <div className='px-0 pr-lg-3'>
                <div className={`icon-rounded mb-3 bg-${block.color}-light`}>
                  <Icon
                    icon={block.icon}
                    className={`w-2rem h-2rem text-${block.color}`}
                  />
                </div>
                <h5 className='test-first-letter'>{t(block.title)}</h5>
                <p className='mb-5 mb-lg-0'>{t(block.content)}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default Discover
