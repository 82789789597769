import React from 'react'
import { isMobile } from 'react-device-detect'
import { Button } from 'reactstrap'
import { HideBetween, HideOn, HideScroll } from 'react-hide-on-scroll'
import { isBrowser } from 'common'

const Fab = (props) => {
  if (isBrowser()) {
    const btn = (
      <Button
        onClick={props.onClick ?? (() => null)}
        color='primary'
        size='lg'
        className='shadow'
        style={{
          position: 'fixed',
          bottom: '2rem',
          right: '2rem',
          zIndex: 1000
        }}
      >
        {props.children}
      </Button>
    )
    if (isMobile) {
      return (
        <HideScroll variant='up'>
          <HideOn inverse showOnPageInit={false} height={100}>
            {btn}
          </HideOn>
        </HideScroll>
      )
    }
    return (
      <HideBetween height startHeight={0} endHeight={600}>
        {btn}
      </HideBetween>
    )
  }
  return ''
}

export default Fab
